<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">
                Do I Really Need Travel Insurance?
                </h1>
            </div>
            <div class="box b-shdw-3 mt-5">
                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
                <p class="paragraph-text">
                    No-one likes to think of any accidents or problems occurring when they’re going on holiday, but, in reality they could, and you need to be protected.
                </p>
                <p class="paragraph-text">
                    Not only from high medical costs, but also from other complications, such as the financial impact of losing your luggage or cash while on holiday.
                </p>
                <p class="paragraph-text">
                    Travel insurance can cover you in so many different eventualities, some you might not have even considered before. Making choosing the right policy essential to give you peace of mind when you travel.
                </p>   
                <p class="blue-text">Why is travel insurance important?</p>     
                <p class="paragraph-text">
                    There are many different reasons why travel insurance is important, take a look at a number of scenarios below, to see how it helps:
                    <ul class="main-list-class extra-padding-top">
                            <li class="list-item extra-padding-bottom">Imagine you’re in another country on holiday and you’re involved in an accident. The right travel insurance can cover any medical expenses occurred, as well as flights back to the UK.</li>
                            <li class="list-item extra-padding-bottom">During your outbound flight, your luggage is lost and can’t be located by your airline. Travel insurance can compensate you for the value of your belongings.</li>
                            <li class="list-item extra-padding-bottom">Your phone is stolen while in another country. Gadget cover can kick in to replace your technology and help you stay connected.</li>
                            <li class="list-item extra-padding-bottom">Your flight is cancelled and your airline is unable to find you a return flight. Travel insurance can ensure you’re compensated so you can get back to the UK.</li>
                    </ul>
                </p>
                <p class="paragraph-text">
                    That’s just a handful of examples of where travel insurance can help. When shopping around for quotes, look closely at the policies to see exactly what’s included.
                </p>
                <p class="blue-text">Are you legally required to have travel insurance?</p> 
                <p class="paragraph-text">
                    No, it’s not a legal requirement to have travel insurance, unless you’re travelling to the USA where it is essential. Some holiday providers will insist on seeing your travel insurance policy before they’ll release any travel documents, but generally it is up to you to decide.
                </p>
                <p class="paragraph-text">
                    Travel insurance is about protecting yourself and your belongings while you’re away. The last thing you want to return home with is huge, unexpected medical bills or no luggage and no compensation to replace it.
                </p>
                <p class="blue-text">What does travel insurance cover?</p>
                <p class="paragraph-text">
                    There are a number of different things that travel insurance can cover you for while you’re travelling:
                     <ul class="main-list-class extra-padding-top">
                        <li class="list-item extra-padding-bottom"><strong>Holiday Cancellation Cover & Curtailment Cover </strong>(if you need to cancel due to an unforeseen reason or have to cut short your trip)</li>
                        <li class="list-item extra-padding-bottom"><strong>Medical Expenses </strong>(in case of any illness or accident)</li>
                        <li class="list-item extra-padding-bottom"><strong>Gadget Cover </strong>(protect your most valuable belongings, such as your phone, laptop or tablet)</li>
                        <li class="list-item extra-padding-bottom"><strong>Lost Luggage</strong></li>
                        <li class="list-item extra-padding-bottom"><strong>Airline Failure</strong>(such as a delayed, missed or cancelled flight)</li>
                        <li class="list-item extra-padding-bottom"><strong>Personal Accident</strong></li>
                        <li class="list-item extra-padding-bottom"><strong>Mugging</strong> (get back the value of your belongings that were stolen)</li>
                        <li class="list-item extra-padding-bottom"><strong>Personal Liability </strong>(protection if you cause an accident or injury to another person)</li>
                        <li class="list-item extra-padding-bottom"><strong>Theft or Loss of Cash</strong></li>
                     </ul>
                </p> 
                <p class="paragraph-text">
                    If you’re unsure about what level of cover you need for your trip, then it can help to make a list of any valuables you’re taking with you. Think about technology as well as personal possessions such as jewellery, and how much they’d cost to replace.
                </p>
                <p class="paragraph-text">
                    It can sometimes be tempting to simply choose the cheapest policy, however you need to make sure it meets your needs.
                </p>
                <p class="blue-text">What are the different types of travel insurance?</p>
                <p class="paragraph-text">
                    There are a number of different travel insurance policies you can consider for your trip. Here at Covered2go we’ve put together a list of types of insurance we offer:
                    <ul class="main-list-class extra-padding-top">
                        <li class="list-item extra-padding-bottom"><a href="single-trip-travel-insurance" target="_blank" class="text-link">Single Trip Insurance</a> – Perfect for a single trip anywhere in the world, tends to be the most affordable option.</li>
                        <li class="list-item extra-padding-bottom"><a href="annual-trip-travel-insurance" target="_blank" class="text-link">Multi-Trip Insurance </a> – If you’re planning multiple trips throughout the year, a multi-trip policy can work out more cost-effective.</li>
                        <li class="list-item extra-padding-bottom"><a href="longstay-travel-insurance" target="_blank" class="text-link">Longstay Travel Insurance</a> – We can cover trips up to 18 months long, if you’re planning a big adventure around the world!</li>
                        <li class="list-item extra-padding-bottom"><a href="cruise-travel-insurance" target="_blank" class="text-link">Cruise Cover</a> – Get insurance tailored to your cruise holiday. Enjoy additional medical allowance, as well as cover for a missed port departure.</li>
                        <li class="list-item extra-padding-bottom"><a href="group-travel-insurance" target="_blank" class="text-link">Group Insurance</a> – Groups travelling together can enjoy additional benefits, and a potentially lower cost, by all travelling on the same insurance policy.</li>
                        <li class="list-item extra-padding-bottom"><a href="pre-existing-medical-conditions" target="_blank" class="text-link">Pre-Existing Medical Conditions Cover</a> – Use our online medical screening service to tailor a policy that covers your pre-existing medical condition.</li>
                        <li class="list-item extra-padding-bottom"><a href="over-65s-travel-insurance" target="_blank" class="text-link">Over 65s Travel Insurance</a> – Travel insurance for seniors includes a number of additional benefits, including increased medical expenses.</li>
                        <li class="list-item extra-padding-bottom"><a href="sports-activities-cover" target="_blank" class="text-link">Sports Cover </a>– If you’re planning an exhilarating adventure holiday or a golfing break, choose sports cover to ensure you and your equipment are protected.</li>
                    </ul>
                </p>
                <p class="paragraph-text">
                    You need to make sure you have the right type of travel insurance for your trip, so you and loved ones have the best possible protection. Plus, our online quote system makes it so easy to compare our policies side by side, so you can see exactly what’s included.
                </p>
                <p class="blue-text underline">FAQs about Travel Insurance</p>
                <p class="paragraph-text">
                    To help you find out more about travel insurance, we’ve put together a number of frequently asked questions:
                </p>
                <p class="blue-text">Do I need travel insurance for Europe?</p>
                <p class="paragraph-text">
                    Yes, the Foreign and Commonwealth Office still recommend that you purchase travel insurance, even when travelling within Europe.
                </p>
                <p class="paragraph-text">
                     You can still use your EHIC card (European Health Insurance Card), so long as it is still valid. This card covers you for emergency medical treatment while travelling in Europe. Your treatment will be free or you’ll be expected to make a patient contribution.   
                </p>
                <p class="paragraph-text">
                   If your card is expired, you will need to get a GHIC, <a href="https://www.nhs.uk/using-the-nhs/healthcare-abroad/apply-for-a-free-uk-global-health-insurance-card-ghic/" target="_blank" class="text-link">click here to apply for the new GHIC</a>.     
                </p>
                <p class="paragraph-text">
                    The card doesn’t cover any private medical care or any care for injuries as a result of skiing. It also doesn’t cover mountain rescue costs or if you’re travelling on a cruise.    
                </p>
                <p class="paragraph-text">
                   You don’t get the additional benefits you get with travel insurance, such as lost luggage or holiday cancellation cover.     
                </p>
                <p class="blue-text">Does travel insurance protect my possessions too?</p>
                <p class="paragraph-text">
                    If you choose the right insurance policy, you can get cover for both yourself and your possessions. Think about any high value items you might be travelling with, such as your smartphone or tablet, to get the right level of cover to replace them if they were lost or stolen.
                </p>
                <p class="blue-text">Where can I find cheap travel insurance?</p>
                <p class="paragraph-text">
                    <a href="get-quote" target="_blank" class="text-link">Get a quote online now!</a> You can compare our different policies to find the one that’s right for you. You can see the benefits offered and find one that’s perfectly tailored to your needs and your budget.
                </p>
                <p class="paragraph-text">
                    Here at Covered2go we specialise in travel insurance, so we can offer simple and flexible travel insurance policies to suit a wide range of holidays.
                </p>

                <br />
                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />
                <info-boxes />
                <!-- <trustpilot-banner /> -->
            </div>
        </div>
    </transition>
</template>
<script>
    import InfoBoxes from '../components/common/InfoBoxes';
    // import TrustpilotBanner from '@/components/common/trustpilot/TrustpilotBanner';
    import InfoButtonOptions from '@/components/common/InfoButtonOptions';
    export default {name: "TravelByDestination",data() {return {}},methods: {},components: {
        InfoBoxes,
        // TrustpilotBanner,
        InfoButtonOptions,
    },created() {}}
</script>

<style lang="scss" scoped>
    .main-page-title {color: $c2g_orange; font-size:130%; text-decoration:none; text-align:left; padding-bottom:15px; font-weight: bold;}
    .paragraph-text {color:black; font-size:100%; padding-bottom:15px;}
    .main-list-class{padding-left:25px; padding-bottom:15px;}
    .list-item{list-style-type: disc; color:black;}
    .blue-text {margin-top: 10px; text-align:left; color: $c2g_blue; font-weight: bold; font-size:115%; padding-bottom:15px;}
    .bold-text{font-weight:bold;}
    .text-link{color:$c2g_orange; text-decoration: underline;}
    .extra-padding-bottom{padding-bottom:10px}
    .extra-padding-top{padding-top:10px}
    .underline{text-decoration:underline}
</style>